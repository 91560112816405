.labelSlide-enter {
  opacity: 0;
  width: 0%;
}

.labelSlide-enter-active {
  opacity: 0;
  width: 25%;
}

.labelSlide-enter-done {
  opacity: 1;
  width: 30%;
  transition: all 250ms ease-in;
}

label.labelSlide-exit {
  opacity: 1;
  width: 30%;
}

label.labelSlide-exit-done {
  width: 0%;
  opacity: 0;
  transition: all 250ms ease-in;
}
