@import "variables";

//
// general
//
.view-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.view-header {
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
}

.custom-action {
  margin-right: 5px;
}

//
// Login View
//
.login-view-message {
  align-self: stretch;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 5px;
  background-color: #ff902b;
  color: white;
  text-align: center;

  &.logout {
    background-color: #27c24c;
  }

  &.error {
    background-color: rgb(225, 37, 27);
  }
}

//
// Password Update View
//
.password-rules-container {
  margin-top: 50px;
  color: $charcoal;

  li {
    margin-top: 10px;
  }
}
