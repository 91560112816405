@import "variables";

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  min-width: 700px;

  h3 {
    margin-bottom: 40px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  a {
    color: $blue-jay;
    text-align: right;
    text-decoration: none;
    display: inline-block;
    font-size: 1.4rem;
  }
}

.label-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;

  label {
    width: 30%;
    text-align: right;
    margin-top: 10px;
  }

  input.form-input {
    padding: 10px;
    border-radius: 3px;
    border: 1px solid darken($new-charcoal, 10%);
    color: inherit;
    flex-grow: 1;
    background-color: transparent;

    &:focus {
      outline: none;
      border-color: $new-charcoal;
    }

    &.readOnly {
      border-color: transparent;
      &:focus {
        outline: none;
      }
    }

    &.login-username {
      text-align: center;
      margin-bottom: 10px;
    }
    &.login-username.readOnly {
      margin-bottom: 40px;
      font-size: 1.9rem;
    }
  }
}

.checkbox-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
}

.link-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-message {
  text-align: center;
  font-style: italic;
}

.input-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  &.field-error {
    input {
      border: 1px solid $lm-red;
    }
  }
}

.google-signin {
  display: inline-block;
}

@media (max-width: 400px) {
  .form-container {
    min-width: unset;
  }

  .label-group {
    flex-direction: column;

    .input-container {
      width: 100%;
    }

    label {
      margin-bottom: 5px;
      text-align: left;
    }
  }
}

.forgot-password-message {
  flex-grow: 1;
  text-align: center;

  svg {
    color: $validation;
  }
}

a {
  color: $purple-5;
}

input {
  accent-color: $purple-5;
  outline: none;
  border-color: $new-charcoal;
}
