@import "variables";

@font-face {
  font-family: "HelveticaNeue";
  src: url("/assets/fonts/HelveticaNeueLTStd-Roman.otf") format("opentype");
  font-weight: 400; /* Regular */
  font-style: normal;
  descent-override: 0%; // Used to remove extra space below font (https://web.dev/articles/css-size-adjust#descent-override)
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("/assets/fonts/HelveticaNeueLTStd-Md.otf") format("opentype");
  font-weight: 500; /* Medium */
  font-style: normal;
  descent-override: 0%; // Used to remove extra space below font, specifically for buttons (https://web.dev/articles/css-size-adjust#descent-override)
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("/assets/fonts/HelveticaNeueLTStd-Bd.otf") format("opentype");
  font-weight: 700; /* Bold */
  font-style: normal;
  descent-override: 0%;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("/assets/fonts/HelveticaNeueLTStd-Hv.otf") format("opentype");
  font-weight: 900; /* Heavy */
  font-style: normal;
  descent-override: 0%;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "HelveticaNeue";
  font-weight: 400;
  color: $font-primary;
  font-size: 1.6rem;
}
