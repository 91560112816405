@import "variables";

$charcoal: #353535;
$purple_5: #7300a8;
$primary: $purple_5;

//
// Main
//
html,
body {
  min-height: 100%;
}

body {
  background-color: white;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//
// Loader
//
.loading-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  h2 {
    color: $charcoal;
  }

  .spinner-icon {
    color: $primary;
  }
}

.hidden {
  visibility: hidden;
  height: 1px;
  width: 1px;
  position: absolute;
}
